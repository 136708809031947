import _ from "lodash";
import types from "../constans";
import { checkEventAndAccountSubscription, getInstanceAxios, prepareEventData } from "../../utils/helpers";
import config from "../../utils/config";
import {
    addEventLogoAction,
    removeEventLogoAction,
    setCurrentEventAction,
    updateCurrentEventAction
} from "../controller/actions";

import { setCurrentUserAction } from "../users/actions";
import { setEventRegistrationsAction } from "../checkin/actions";
import { webinarEnabledAction } from "../registration/actions";

export const setAttendingCheckInEventRegistrationsCount = (count) => ({
    type: types.SET_ATTENDING_CHECK_IN_EVENT_REGISTRATIONS_COUNT,
    count,
});

export const setSponsorCount = (count) => ({
    type: types.SET_SPONSOR_COUNT,
    count,
});

export const setAttendingEventRegistrationsCount = (count) => ({
    type: types.SET_ATTENDING_EVENT_REGISTRATIONS_COUNT,
    count,
});

export const setAllEventRegistrationsCount = (count) => ({
    type: types.SET_ALL_EVENT_REGISTRATIONS_COUNT,
    count,
});

export const setLastFiveRegistrations = (lastFiveRegistrations) => ({
    type: types.SET_LAST_FIVE_REGISTRATIONS,
    lastFiveRegistrations,
});
export const setTicketsCounts = (ticketsCounts) => ({
    type: types.SET_TICKET_COUNTS,
    ticketsCounts,
});

export const setIsMenuAdminOpenAction = (flag) => ({
    type: types.SET_IS_MENU_ADMIN_OPEN,
    flag
});

export const setIsShowDeleteEventModalAction = (flag) => ({
    type: types.SET_IS_SHOW_DELETE_EVENT_MODAL,
    flag
});

export const setIsShowCopyTemplateKeyModal = (flag) => ({
    type: types.SET_IS_SHOW_COPY_TEMPLATE_KEY_MODAL,
    flag
});

export const setShowExplanationAfterCreateEvent = (flag) => ({
    type: types.SET_SHOW_EXPLANATION_AFTER_CREATE_EVENT,
    flag,
});

export const setFeaturesTierLevelAction = featuresTierLevel => ({
    type: types.SET_FEATURES_TIER_LEVEL,
    featuresTierLevel
})

export const setOpenSidebarAction = (flag, sidebarType = null, data = null, isNeedBack = false, urlType = null, history = null) => {
    if (urlType && history) {
        const pathname = history.location.pathname;
        const search = `?show=${urlType}`;
        history.push(`${pathname}${search}`);
    }

    return ({
        type: types.SET_OPEN_SIDEBAR,
            flag,
            sidebarType,
            data,
            isNeedBack
    })
};

export const setOrganizerSidebarDataBubbleUserAction = (data) => ({type: types.SET_USER, data});
export const setDefaultSurveyAction = (defaultSurvey) => ({type: types.SET_DEFAULT_SURVEY, defaultSurvey});//Questions and name/description
export const setEditableSurveyAction = (editableSurvey) => ({type: types.SET_EDITABLE_SURVEY, editableSurvey});//questions
export const setEditableWrapupSurveyAction = (editableWrapupSurvey) => ({
    type: types.SET_EDITABLE_WRAPUP_SURVEY,
    editableWrapupSurvey
});//wrapup
export const setCustomWrapupSurveysAction = (customWrapupSurveys) => ({
    type: types.SET_CUSTOM_WRAPUP_SURVEYS,
    customWrapupSurveys
});//customWrapupSurveys - array
export const createCustomWrapupSurveyAction = (createdWrapupSurvey) => ({
    type: types.CREATE_CUSTOM_WRAPUP_SURVEY,
    createdWrapupSurvey
});
export const setInitialNavItemsAction = (initialNavItems) => ({type: types.SET_INITIAL_NAV_ITEMS, initialNavItems});//initialNavItems - array

export const getOrganizersAction = async () => {
    const {data} = await getInstanceAxios().get(`${config.NODE_API_URL}/organizers`, {crossDomain: true});
    if (data && data.status === 200) {
        const {accounts} = data;
        return {accounts};
    } else {
        console.warn('data', data);
        return [];
    }
};

export const createEventAction = (dataValues, history) => async (dispatch, getState) => {
    const {data} = await getInstanceAxios().post(`${config.NODE_API_URL}/organizers/create-event`, dataValues, {crossDomain: true});
    if (data && data.status === 200 && data.event) {
        const {users: {currentUser}} = getState();
        const preparedEventData = prepareEventData(data.event);
        if (preparedEventData.webinarEnabled) {
            await dispatch(webinarEnabledAction({isLiveKickoff: preparedEventData.webinarEnabled, eventId: preparedEventData.eventId}));
        }
        dispatch(setCurrentEventAction(preparedEventData));
        dispatch(setCurrentUserAction({...currentUser, counterOfCreatedEvents: data.counterOfCreatedEvents}));
        if (history) {
            const count = currentUser && currentUser.counterOfCreatedEvents && Number(currentUser.counterOfCreatedEvents)
                ? currentUser.counterOfCreatedEvents + 1
                : 1;
            const modalType = count === 1
                ? '1stSuccess'
                : 'nthSuccess';
            localStorage.setItem('typeSuccessMessagePopup', modalType);
            localStorage.setItem('showExplanationAfterCreateEvent', '1');
            // localStorage.setItem('showSuccessMessagePopup', '1');
            history.push({
                pathname: `/m/${preparedEventData.eventId}/overview`,
                state: {
                    typeSuccessMessagePopup: 'nthSuccess',
                    showSuccessMessagePopup: true
                }
            });
        }
    } else {
        console.warn('data', data);
    }
};

export const updateEventAction = (updatedCurrentEvent, isCore) => async (dispatch, getState) => {
    const response = await getInstanceAxios().put(`${config.NODE_API_URL}/organizers/update-event`, updatedCurrentEvent, {crossDomain: true});

    if (response && response.status === 200 && response.data.updatedCurrentEvent) {
        const {currentEvent} = getState().controller;
        if (isCore && !updatedCurrentEvent.onlyTitleAndImage && currentEvent.webinarEnabled !== updatedCurrentEvent.webinar_enabled) {
            await dispatch(webinarEnabledAction({isLiveKickoff: updatedCurrentEvent.webinar_enabled, eventId: updatedCurrentEvent.event}));
        }
        dispatch(updateCurrentEventAction(response.data.updatedCurrentEvent));
        // if (history) history.push(`/organizer/overview/${response.data.updatedCurrentEvent.eventId}`);
        return response;
    } else {
        console.error('updateEventAction error response = ', response);
    }
};

export const saveVideoThumbnailVideoRecorder = ({type, image, eventId, eventKey}) => async (dispatch) => {
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/organizers/video-recorder-event-video-thumbnail`, {type, image, eventId, eventKey});
    const updatedCurrentEvent = _.get(response, 'data.updatedCurrentEvent');
    const status = _.get(response, 'data.status');

    if (status === 200 && updatedCurrentEvent) {
        dispatch(updateCurrentEventAction(updatedCurrentEvent));
        return response.data;
    } else {
        console.error('updateEventAction error response = ', response);
    }
};

export const startOnDemand = (eventId) => async (dispatch, getState) => {
    const response = await getInstanceAxios().put(`${config.NODE_API_URL}/organizers/start-on-demand`, { eventId });

    if (response && response.status === 200) {
        const {currentEvent} = getState().controller;
        dispatch(updateCurrentEventAction({
            ...currentEvent,
            startOnDemand: true
        }));
        return response;
    } else {
        console.error('startOnDemand error response = ', response);
    }
};

export const startRotations = (eventId) => async (dispatch, getState) => {
    const response = await getInstanceAxios().put(`${config.NODE_API_URL}/organizers/start-rotations`, { eventId });

    if (response && response.data && response.data.status === 200) {
        const {internalEndDateAndTime, numberOfSessions, minPerSession, startDateAndTime} = response.data;
        const {currentEvent} = getState().controller;
        dispatch(updateCurrentEventAction({
            ...currentEvent,
            startDateAndTime,
            internalEndDateAndTime,
            numberOfSessions,
            minPerSession
        }));
        return response;
    } else {
        console.error('startOnDemand error response = ', response);
    }
};

export const saveVideoThumbnail = ({type, image, eventId}) => async (dispatch) => {
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/organizers/event-video-thumbnail`, {type, image, eventId});
    const updatedCurrentEvent = _.get(response, 'data.updatedCurrentEvent');
    const status = _.get(response, 'data.status');

    if (status === 200 && updatedCurrentEvent) {
        dispatch(updateCurrentEventAction(updatedCurrentEvent));
        return response.data;
    } else {
        console.error('updateEventAction error response = ', response);
    }
};

export const saveRecordedVideoLink = (updatedCurrentEvent) => async (dispatch) => {
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/organizers/save-recorded-video-link`, {...updatedCurrentEvent});

    if (response && response.status === 200 && response.data.updatedCurrentEvent) {
        dispatch(updateCurrentEventAction(response.data.updatedCurrentEvent));
        return response;
    } else {
        console.error('saveRecordedVideoLink error response = ', response);
    }
};

export const copyEventAction = (eventId, history, isNeedUpdateData = false, forceCopy = false) => async (dispatch, getState) => {
    if (!forceCopy) {
        const {copyWarning, removedFeatures} = checkEventAndAccountSubscription({getState, eventId});

        if (copyWarning) {
            return dispatch(setShowWarningPopupAction(true, eventId,'copy', removedFeatures));
        }
    }

    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/organizers/copy-event`, {eventId, action: 'copy', forceCopy}, {crossDomain: true});

    if (response && response.data && response.data.status === 200 && response.data.event) {
        const preparedEventData = prepareEventData(response.data.event);
        if (!isNeedUpdateData) {
            dispatch(setCurrentEventAction(preparedEventData));
        }
        // if (history) history.push(`/organizer/overview/${preparedEventData.eventId}`);
        if (history) {
            localStorage.setItem('typeSuccessMessagePopup', 'copyEventSuccess');
            localStorage.setItem('showExplanationAfterCreateEvent', '1');
            // localStorage.setItem('showSuccessMessagePopup', '1');
            history.push({
                pathname: `/m/${preparedEventData.eventId}/overview`,
                state: {
                    typeSuccessMessagePopup: 'copyEventSuccess',
                    showSuccessMessagePopup: true,
                    isNeedUpdateData
                }
            });
            window.location.reload();
        }
        return response;
    } else {
        console.error('copyEventAction error response = ', response);
    }
};

export const shareTemplateAction = (eventId) => async (dispatch) => {
    const response = await getInstanceAxios().get(`${config.NODE_API_URL}/organizers/share-template-key/${eventId}`);
    if (response && response.data && response.data.status === 200 && response.data.templateKey) {
        dispatch({
            type: 'SET_TEMPLATE_KEY',
            key: response.data.templateKey
        });
        dispatch({
            type: 'SET_IS_SHOW_COPY_TEMPLATE_KEY_MODAL',
            flag: true
        });
        return response.data.templateKey;
    } else {
        console.error('shareTemplateAction error response = ', response);
        return null;
    }
};

export const setIsShowMakeRecurringModalAction = (flag) => ({
    type: types.SET_IS_SHOW_MAKE_RECURRING_EVENT_MODAL,
    flag
});

export const makeRecurringAction = ({ eventId, repeating, isHomePage }) => async (dispatch, getState) => {
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/organizers/make-recurring-event`, {
        eventId, repeating
    });
    const eventData = _.get(response, 'data.currentEvent', null);
    const status = _.get(response, 'data.status', null);

    if (eventData && status === 200 ) {
        const {currentEvent} = getState().controller;
        const preparedEventData = prepareEventData(eventData, currentEvent.account);
        dispatch(setCurrentEventAction(preparedEventData));
    } else {
        console.error('makeRecurringAction error response = ', response);
        return null;
    }

    if (isHomePage) document.location = `/m/${eventId}/overview`;
};

export const pushSettingsRorRecurringEvent = ({ baseEventId, targetEventId }) => async (dispatch, getState) => {
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/organizers/push-settings-for-recurring-event`, {
        baseEventId, targetEventId
    });
    const status = _.get(response, 'data.status', null);
    const targetEvent = _.get(response, 'data.targetEvent', null);

    if (status === 200) {
        const {currentEvent} = getState().controller;
        dispatch(setCurrentEventAction({
            ...currentEvent,
            recurringEvents: currentEvent.recurringEvents.map((event)=> event.event === targetEventId ? targetEvent : event)
        }));
    }

    return status;
};

export const getEventIdByTemplateKeyAction = async (templateKey) => {
    const response = await getInstanceAxios().get(`${config.NODE_API_URL}/organizers/get-event-id-by-template-key/${templateKey}`);
    if (response && response.data && response.data.status === 200 && response.data.eventId) {
        return response.data.eventId;
    }
    return null;
};

export const createEventTemplateByKeyAction = (eventId) => async (dispatch, getState) => {
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/organizers/copy-event`, {
        eventId,
        action: "templateByKey"
    });

    if (response && response.data && response.data.status === 200 && response.data.event) {
        const { events } = getState().home;
        const { templateEventsCount, templateEvents } = events;

        dispatch({
            type: types.SET_HOME_PAGE_EVENTS,
            events: {
                ...events,
                templateEventsCount: templateEventsCount + 1,
                templateEvents: [response.data.event].concat(templateEvents)
            }
        });

        return {
            templateEventId: response.data.event.event,
            accountId: response.data.event.account_id
        };
    } else {
        console.error("createEventTemplateByKeyAction error response = ", response);
        return false;
    }
};

export const createEventTemplateAction = (eventId, history, isNeedUpdateData = false, updateEventList, showMessage = false, moveToHome = false, forceCopy = false) => async (dispatch, getState) => {
    if (!forceCopy) {
        const {copyWarning, removedFeatures} = checkEventAndAccountSubscription({getState, eventId});

        if (copyWarning) {
            return dispatch(setShowWarningPopupAction(true, eventId, 'template', removedFeatures));
        }
    }

    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/organizers/copy-event`, {eventId, action: 'template', forceCopy}, {crossDomain: true});

    if (response && response.data && response.data.status === 200 && response.data.event) {
        const preparedEventData = prepareEventData(response.data.event);
        if (!isNeedUpdateData) {
            dispatch(setCurrentEventAction(preparedEventData));
        }
        if (showMessage) {
            dispatch(setNewEventTemplateIdAction(eventId))
        }
        if (updateEventList) {
            const {events} = getState().home;
            const {templateEventsCount, templateEvents} = events;

            console.log('DEBUG');

            dispatch({
                type: types.SET_HOME_PAGE_EVENTS,
                events: {
                    ...events,
                    templateEventsCount: templateEventsCount + 1,
                    templateEvents: [...templateEvents, response.data.event]
                },
            });
        }
        if (moveToHome) {
            history.push({
                pathname: `/home`,
                state: {openTemplate: true, account: response.data.event.account_id}
            })
        }

        return response;
    } else {
        console.error('createEventTemplateAction error response = ', response);
    }
};

export const setShowWarningPopupAction = (showWarningCopyPopup, eventId, typeOfActionForWarningCopyPopup = 'copy', removedFeatures = []) =>
    ({type: types.SET_SHOW_WARNING_COPY_POPUP, showWarningCopyPopup, eventId, typeOfActionForWarningCopyPopup, removedFeatures});

export const getFeaturesTierLevelAction = () => async (dispatch) => {
    const responseFeatures = await getInstanceAxios().get(`${config.NODE_API_URL}/organizers/features-tear-level`);

    if (responseFeatures.data.status === 200) {
        dispatch(setFeaturesTierLevelAction(responseFeatures.data.result));
    }
}

export const setNewEventTemplateIdAction = (eventId = '') => ({type: types.SET_NEW_TEMPLATE_EVENT_ID, eventId})

export const removeEventAction = (eventId) => async (dispatch) => {
    const response = await getInstanceAxios().delete(`${config.NODE_API_URL}/organizers/remove-event/${eventId}`, {crossDomain: true});

    if (response && response.data && response.data.status === 200) {
        dispatch(setCurrentEventAction({}));
        window.document.location = '/home';
        return response;
    } else {
        console.error('removeEventAction error response = ', response);
    }
};

export const addEventSponsorLogoAction = (updatedCurrentEvent) => async (dispatch) => {
    const response = await getInstanceAxios().put(`${config.NODE_API_URL}/organizers/update-event`, updatedCurrentEvent, {crossDomain: true});

    if (response && response.status === 200 && response.data.newSponsorLogo) {
        dispatch(addEventLogoAction(response.data.newSponsorLogo));
        return response;
    } else {
        console.error('addEventSponsorLogoAction error response = ', response);
    }
};

export const removeEventSponsorLogoAction = (updatedCurrentEvent) => async (dispatch) => {
    const response = await getInstanceAxios().put(`${config.NODE_API_URL}/organizers/update-event`, updatedCurrentEvent, {crossDomain: true});

    if (response && response.status === 200) {
        dispatch(removeEventLogoAction(response.data.removedSponsorLogo));
        return response;
    } else {
        console.error('removeEventSponsorLogoAction error response = ', response);
    }
};

export const getCoOrganizersAction = (eventId) => async (dispatch) => {

    const {data} = await getInstanceAxios().get(`${config.NODE_API_URL}/organizers/manage/${eventId}`, {crossDomain: true});

    if (data && data.status === 200 && data.organizers) {
        dispatch(setCoOrganizers(JSON.parse(JSON.stringify(data)).organizers));
    }

    console.warn('getCoOrganizersAction', data);
    return [];
};

export const addCoOrganizersAction = ({eventId, email, firstName, lastName}) => async (dispatch, getState) => {
    const {data} = await getInstanceAxios().post(`${config.NODE_API_URL}/organizers/manage/invite`, {
        eventId, email, firstName, lastName
    }, {crossDomain: true});
    const {organizer: {coOrganizers}} = getState();
    if (data && data.status === 200) dispatch(setCoOrganizers([...coOrganizers, data.coOrganizer]));
    return data;
};

export const setCoOrganizers = (coOrganizers) => {
    return {
        type: types.SET_CO_ORGANIZERS,
        coOrganizers
    }
};

export const manageCoOrganizersAction = ({user, type, eventId}) => async (dispatch, getState) => {
    const {data} = await getInstanceAxios().post(`${config.NODE_API_URL}/organizers/manage`, {
        eventId,
        user,
        type
    }, {crossDomain: true});

    const {organizer: {coOrganizers}} = getState();

    if (data.status === 200 && (data.coOrganizerStatus === 'revokeInvite')) {
        dispatch(setCoOrganizers(coOrganizers.filter((organizer) => organizer.user !== data.user)));
    }

    if (data.status === 200 && (data.coOrganizerStatus === 'deactivated' || data.coOrganizerStatus === 'activated' || data.coOrganizerStatus === 'invited')) {
        const index = coOrganizers.findIndex((organizer) => organizer.user === data.user);
        if (index !== -1) coOrganizers[index] = {...coOrganizers[index], status: data.coOrganizerStatus};
        dispatch(setCoOrganizers([...coOrganizers]));
    }

    return data.status;
};

export const setLoading = (isLoading) => {
    return {
        type: types.SET_CO_ORGANIZERS_LOADING,
        isLoading
    }
};

export const setLoadingAction = (isLoading) => ({type: types.SET_LOADING, isLoading});

export const createUpdateEventReminderAction = (dataValues) => async (dispatch) => {
    dispatch({
        type: types.SET_CO_ORGANIZERS_LOADING,
        isLoading: true
    });

    try {
        const {data} = await getInstanceAxios().post(`${config.NODE_API_URL}/emails/add-new-event-reminder`
            , dataValues, {crossDomain: true});
        return data;
    } catch (e) {
        if (e.response && e.response.status === 429) {
            return {
                error: "Too many requests, try again later..."
            };
        }
        return {
            error: e.response && e.response.data && e.response.data.message ? e.response.data.message : 'Something went wrong'
        };
    } finally {
        dispatch({
            type: types.SET_CO_ORGANIZERS_LOADING,
            isLoading: false
        });
    }
};

export const getEmailsTemplatesPreviewAction = (eventId) => async () => {
    const {data} = await getInstanceAxios()
        .get(`${config.NODE_API_URL}/emails/event-reminders-preview/${eventId}`, {crossDomain: true});

    if (data && data.status === 200) {
        return data;
    }

    return [];
};

export const getEmailsTemplatesDefaultAction = () => async (dispatch) => {

    dispatch({
        type: types.SET_CO_ORGANIZERS_LOADING,
        isLoading: true
    });

    const {data} = await getInstanceAxios()
        .get(`${config.NODE_API_URL}/emails/emails-templates-default`, {crossDomain: true});

    if (data && data.status === 200 && data.emailsTemplatesDefault && data.emailsTemplatesDefault.length >= 0) {
        dispatch({
            type: types.SET_EMAILS_TEMPLATES_DEFAULT,
            payload: data.emailsTemplatesDefault
        });
        return data;
    } else {
        dispatch({
            type: types.SET_CO_ORGANIZERS_LOADING,
            isLoading: false
        });
        return data;
    }
};

export const getEventReminderAction = (eventId, emailType) => async dispatch => {
    dispatch({
        type: types.SET_CO_ORGANIZERS_LOADING,
        isLoading: true
    });
    const {data} = await getInstanceAxios()
        .get(`${config.NODE_API_URL}/emails/event-reminder/${eventId}/${emailType}`, {crossDomain: true});
    if (data.eventReminder) {
        dispatch({
            type: types.SET_EVENT_REMINDER,
            payload: data.eventReminder
        });
    } else {
        dispatch({
            type: types.SET_EVENT_REMINDER,
            payload: {}
        });
        dispatch({
            type: types.SET_CO_ORGANIZERS_LOADING,
            isLoading: false
        });
    }
}

export const getEventRemindersAction = (eventId, userId) => async () => {
    const {data} = await getInstanceAxios()
        .get(`${config.NODE_API_URL}/emails/event-reminders/${eventId}/${userId}`, {crossDomain: true});

    if (data && data.status === 200 && data.eventReminders && data.eventReminders.length >= 0) {
        return data.eventReminders;
    }

    return [];
};

export const createOrUpdateEventWrapupSurveyAction = (dataValues) => async (dispatch) => {
    dispatch(setLoading(true));

    const {data} = await getInstanceAxios().post(`${config.NODE_API_URL}/organizers/create-update-event-wrapup-survey`
        , dataValues, {crossDomain: true});

    if (data && data.status === 200 && data.createdWrapupSurvey) {
        dispatch(createCustomWrapupSurveyAction(data.createdWrapupSurvey));
    }

    if (data && data.status === 200 && data.updatedCurrentEvent) {
        dispatch(updateCurrentEventAction(data.updatedCurrentEvent));
    }

    dispatch(setLoading(false));
    return data;
};

export const getAllCustomEventWrapupSurveyAction = (eventId) => async (dispatch) => {
    dispatch(setLoading(true));

    const {data} = await getInstanceAxios()
        .get(`${config.NODE_API_URL}/organizers/custom-event-wrapup-surveys/${eventId}`, {crossDomain: true});

    // if (data && data.status === 200 && data.customWrapupSurveys) {
    //     dispatch(setCustomWrapupSurveysAction(data.customWrapupSurveys));
    // }

    dispatch(setLoading(false));
    return data;
};

export const getIntroductionsMadeCountAction = (eventId) => async (dispatch, getState) => {
    const {organizer: {introductionsMadeCount}} = getState();
    const {data} = await getInstanceAxios().get(`${config.NODE_API_URL}/organizers/introductions-made/${eventId}`, {crossDomain: true});

    if (data && data.status === 200 && (data.introductionsMadeCount || introductionsMadeCount)) {
        dispatch({
            type: types.SET_INTRODUCTIONS_MADE_COUNT,
            introductionsMadeCount: data.introductionsMadeCount
        });
        return data.introductionsMadeCount;
    }

    return 0;
};

export const getWrapupSurveyResultsAction = async (eventId, enableCache) => {
    const {data} = await getInstanceAxios(enableCache).get(`${config.NODE_API_URL}/organizers/wrapup-survey-results/${eventId}`);
    if (data && data.status === 200 && data.wrapupSurveyResults) {
        return data.wrapupSurveyResults;
    }

    return null;
};

export const getCsvAttendeesDataAction = async (eventId) => {
    const response = await getInstanceAxios(true).post(`${config.NODE_API_URL}/organizers/get-attendees-csv-filtered-data`, {
        eventId: eventId,
        search: '',
        filters: {
            status: {},
            priorityMatching: { "vip_matching": false, "is_sponsor": false },
            tags: {}
        },
    });

    if (response && response.data && response.data.status === 200 && response.data.eventRegistrations) {
        return response.data.eventRegistrations;
    }

    return null;
};

export const getSupplyDemandByTagAction = async (eventId, enableCache) => {
    const {data} = await getInstanceAxios(enableCache).get(`${config.NODE_API_URL}/organizers/supply-demand-by-tag/${eventId}`);
    if (data && data.status === 200 && data.dataByTag) {
        return data.dataByTag;
    }

    return null;
};

export const getPopularAttendeesData = async (eventId, enableCache) =>  {
    const {data} = await getInstanceAxios(enableCache).get(`${config.NODE_API_URL}/organizers/most-desired-attendee/${eventId}`);
    if (data && data.status === 200 && data.mostDesiredAttendee) {
        return data.mostDesiredAttendee;
    }

    return null;
};

export const getEmailActivityData = async (eventId, enableCache) => {
    const {data} = await getInstanceAxios(enableCache).get(`${config.NODE_API_URL}/organizers/email-activity/${eventId}`);

    if (data && data.status === 200 && data.emailActivity) {
        return data.emailActivity;
    }

    return null;
};

export const getDefaultEventWrapupSurveyAction = (eventId) => async (dispatch) => {
    dispatch(setLoading(true));

    const {data} = await getInstanceAxios()
        .get(`${config.NODE_API_URL}/organizers/default-event-wrapup-survey/${eventId}`, {crossDomain: true});

    if (data && data.status === 200) {
        dispatch(setDefaultSurveyAction(data.wrapupSurvey));
    }

    dispatch(setLoading(false));
    return data;
};

export const videoRecordGetS3UploadParamsAction = async ({name, eventKey, contentType}) => {
    const {data} = await getInstanceAxios()
        .post(`${config.NODE_API_URL}/organizers/video-record-s3-upload-params`, { eventKey, contentType, name });

    return data;
};

export const videoRecordS3UploadVideoAction = async ({name, eventKey}) => {
    const {data} = await getInstanceAxios()
        .post(`${config.NODE_API_URL}/organizers/video-record-s3-upload-video`
        , { name, eventKey});

    return data;
};

export const updateEmailStatusAction = (eventId, emailField, value) => async (dispatch) => {
    const updatedCurrentEvent = {
        type: 'updateEmailStatus',//required
        event: eventId,//required
        emailField,
        value
    };

    await dispatch(updateEventAction(updatedCurrentEvent));
};

export const individualMatchingSwitcherAction = ({eventId, flag}) => async (dispatch, getState) => {
    dispatch(setLoading(true));

    const {data} = await getInstanceAxios()
        .post(`${config.NODE_API_URL}/organizers/individual-matching-switcher`,
            {eventId, flag},
            {crossDomain: true}
        );

    if (data && data.status === 200) {
        const {currentEvent} = getState().controller;
        dispatch(setCurrentEventAction({
            ...currentEvent,
            individualMatching: flag,
            chooseMatchesEarly: !flag ? 0 : currentEvent.chooseMatchesEarly,
            emailThreeDayMatches: (flag && currentEvent.chooseMatchesEarly) ? 1 : 0,
            emailTwoDayMatches: (flag && currentEvent.chooseMatchesEarly) ? 1 : 0,
            emailOneDayMatches: 0,
        }));
    }

    dispatch(setLoading(false));
    return true;
};

export const chooseMatchingEarlySwitcherAction = ({eventId, flag}) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    const {data} = await getInstanceAxios()
        .post(`${config.NODE_API_URL}/organizers/choose-matches-early-switcher`,
            {eventId, flag},
            {crossDomain: true}
        );

    if (data && data.status === 200) {
        const {currentEvent} = getState().controller;
        dispatch(setCurrentEventAction({
            ...currentEvent,
            chooseMatchesEarly: flag,
            emailThreeDayMatches: !!flag ? 1 : 0,
            emailTwoDayMatches: !!flag ? 1 : 0,
            emailOneDayMatches: 0,
        }));
    }

    dispatch(setLoading(false));
    return true;
}

export const saveABMatchingTagsAction = ({tags, algorithm}) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    const {currentEvent} = getState().controller;
    const {data} = await getInstanceAxios()
        .post(`${config.NODE_API_URL}/organizers/save-ab-matching-tags`,
            {tags, algorithm, eventId: currentEvent.eventId},
            {crossDomain: true}
        );

    if (data && data.status === 200) {
        dispatch(setCurrentEventAction({
            ...currentEvent,
            eventTags: data.eventTags,
            algorithm: data.currentEvent.algorithm,
            sessionFormatRematch: data.currentEvent.session_format_rematch,
            sessionFormat: data.currentEvent.session_format
        }));
        if (data.eventRegistrations) dispatch(setEventRegistrationsAction(data.eventRegistrations));
    }

    dispatch(setLoading(false));
    return true;
};

export const saveMatchingTagsAction = ({tags}) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    const {currentEvent} = getState().controller;
    const {data} = await getInstanceAxios()
        .post(`${config.NODE_API_URL}/organizers/save-matching-tags`,
            {tags, eventId: currentEvent.eventId},
            {crossDomain: true}
        );

    if (data && data.status === 200) {
        dispatch(setCurrentEventAction({...currentEvent, eventTags: data.eventTags, algorithm: data.algorithm}));
        if (data.eventRegistrations) dispatch(setEventRegistrationsAction(data.eventRegistrations));
    }

    dispatch(setLoading(false));
    return true;
};

export const saveGroupSizeAction = (request) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    const {currentEvent} = getState().controller;
    const {data} = await getInstanceAxios()
        .post(`${config.NODE_API_URL}/organizers/save-group-size`,
            {...request, eventId: currentEvent.eventId},
            {crossDomain: true}
        );

    const {checkin: {eventRegistrations}} = getState();

    if (data && data.status === 200) {
        dispatch(setCurrentEventAction({...currentEvent, sessionFormat: data.sessionFormat}));
        if (request.pair === 'buffer' && request.buffer) {
            const updatedEventRegistrations = eventRegistrations
                .map(eventRegistration =>
                    ({
                        ...eventRegistration,
                        organizer_mode: request.buffer.includes(eventRegistration.id)
                            ? 'yes'
                            : 'no'
                    })
                );
            dispatch(setEventRegistrationsAction([...updatedEventRegistrations]));
        }
        if (request.pair === 'break' || request.pair === '3-way') {
            dispatch(setEventRegistrationsAction(eventRegistrations.map(eventRegistration =>
                ({...eventRegistration, organizer_mode: 'no'}))));
        }
    }
    dispatch(setLoading(false));
    return true;
};

export const sendEmailReminderToMyselfAction = (dataValues) => async (dispatch) => {
    try {
        dispatch({
            type: types.SET_CO_ORGANIZERS_LOADING,
            isLoading: true
        });

        await getInstanceAxios().post(`${config.NODE_API_URL}/emails/emails-reminder-to-myself`
            , dataValues, {crossDomain: true});

        dispatch({
            type: types.SET_CO_ORGANIZERS_LOADING,
            isLoading: false
        });

        return 'Success';
    } catch (e) {
        if (e.response && e.response.status === 429) {
            return 'Too many requests, try again later...';
        }
        return 'Something went wrong, try again later...';
    }
};

export const generateEmailCopyAction = (dataValues) => async (dispatch) => {
    try {
        dispatch({
            type: types.SET_CO_ORGANIZERS_LOADING,
            isLoading: true
        });

        const {data} = await getInstanceAxios().post(`${config.NODE_API_URL}/organizers/generate-email-copy-ai`, dataValues);

        dispatch({
            type: types.SET_CO_ORGANIZERS_LOADING,
            isLoading: false
        });

        return data.email;
    } catch (e) {
        if (e.response && e.response.status === 429) {
            return { error: "Too many requests, try again later..." };
        }
        return { error: "Something went wrong, try again later..." };
    }
};

export const getPublicInstructionsForGenerateEmailCopy = async ({eventId, emailType}) => {
    const { data } = await getInstanceAxios().get(`${config.NODE_API_URL}/organizers/public-instructions-for-generate-email/${eventId}/${emailType}`);
    return data;
};

export const adminReTriggeringEmailsAction = (dataValues) => async () => {
    const {data} = await getInstanceAxios().post(`${config.NODE_API_URL}/emails/admin-re-triggering-emails`
        , dataValues);

    return data;
};

export const adminReTriggeringEmailsAttendeesAction = (dataValues) => async () => {
    const {data} = await getInstanceAxios().post(`${config.NODE_API_URL}/emails/admin-re-triggering-emails-attendees`
        , dataValues, {crossDomain: true});

    return data;
};

export const adminResendEventTeamMemberInviteAction = (dataValues) => async () => {
    const {data} = await getInstanceAxios().post(`${config.NODE_API_URL}/emails/admin-re-send-event-team-member`
        , dataValues, {crossDomain: true});

    return data;
};

export const adminResendAccountTeamMemberInviteAction = (dataValues) => async () => {
    const {data} = await getInstanceAxios().post(`${config.NODE_API_URL}/emails/admin-re-send-account-team-member`
        , dataValues, {crossDomain: true});

    return data;
};

export const updateTagsFromRegistrationsAfterEditTag = ({
                                                            eventId,
                                                            group,
                                                            newTagName,
                                                            previousTagId
                                                        }) => async (dispatch) => {
    const {data} = await getInstanceAxios().put(`${config.NODE_API_URL}/organizers/update-tags-from-registrations-after-edit`
        , {eventId, group, newTagName, previousTagId}, {crossDomain: true});

    if (data && data.status === 200 && data.eventRegistrations) dispatch(setEventRegistrationsAction(data.eventRegistrations));

    return data;
};

export const updateGroupFromRegistrationsAfterEditGroup = ({
                                                               eventId,
                                                               group,
                                                               newGroupName,
                                                               weight,
                                                               required
                                                           }) => async (dispatch) => {
    const {data} = await getInstanceAxios().put(`${config.NODE_API_URL}/organizers/update-group-from-registrations-after-edit`
        , {eventId, group, newGroupName, weight, required}, {crossDomain: true});

    if (data && data.status === 200 && data.eventRegistrations) dispatch(setEventRegistrationsAction(data.eventRegistrations));

    return data;
};


export const getAllAttendeesReport = (accountId) => async () => {
    if (!accountId) return null;
    await getInstanceAxios().get(`${config.NODE_API_URL}/organizers/all-attendee-by-account-report/${accountId}`, {crossDomain: true});
    return null;
};
export const getAllEventsReport = (accountId) => async () => {
    if (!accountId) return null;
    await getInstanceAxios().get(`${config.NODE_API_URL}/organizers/all-events-by-account-report/${accountId}`, {crossDomain: true});
    return null;
};
export const getAllConversationsReport = (accountId) => async () => {
    if (!accountId) return null;
    await getInstanceAxios().get(`${config.NODE_API_URL}/organizers/all-conversations-by-account-report/${accountId}`, {crossDomain: true});
    return null;
};
export const getAllSurveyReport = (accountId) => async () => {
    if (!accountId) return null;
    await getInstanceAxios().get(`${config.NODE_API_URL}/organizers/all-survey-by-account-report/${accountId}`, {crossDomain: true});
    return null;
};

export const updateEventbritePrivateTokenAction = ({ accountId, apiKey, clientSecret }) => async (dispatch, getState) => {
    const {currentEvent} = getState().controller;
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/accounts/update-private-token`,
        {account: accountId, apiKey, clientSecret }, {crossDomain: true}
    );

    if (response && response.data && response.data.status === 200) {
        dispatch(setCurrentEventAction({
            ...currentEvent,
            eventbriteApiKey: apiKey,
            eventbriteClientSecret: clientSecret
        }));
    }

    //{accountId}|${encodeURIComponent(window.location.href)}
    window.open(`https://www.eventbrite.com/oauth/authorize?response_type=code&client_id=${apiKey}&redirect_uri=${config.NODE_API_URL}/eventbrite/redirect?redirectData=${accountId}|${currentEvent.eventId}`, '_blank');
}

export const updateEventbriteEventIdAction = (eventId, eventbriteEventId) => async (dispatch, getState) => {
    const {currentEvent} = getState().controller;

    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/organizers/update-eventbrite-event-id`,
        {eventId, eventbriteEventId }, {crossDomain: true}
    );
    if (response && response.data && response.data.status === 200) {
        dispatch(setCurrentEventAction({...currentEvent, eventbriteEventId}));
    } else {
        return response;
    }
}

export const generateApiSecretTokenAction = (accountId) => async (dispatch, getState) => {
    const {currentEvent} = getState().controller;
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/accounts/generate-secret-token`,
        {account: accountId }, {crossDomain: true}
    );

    if (response && response.data && response.data.status === 200) {
        const {secretToken} = response.data;
        dispatch(setCurrentEventAction({...currentEvent, apiSecretToken: secretToken}));
    }
    return response.data;
}

export const updateVirtualBackgroundImageAction = (data) => async (dispatch) => {
    const updateResponse = await getInstanceAxios().post(`${config.NODE_API_URL}/organizers/virtual-background`,
        data, {crossDomain: true}
    );
    if (updateResponse.data.status === 200) {
        const {defaultVirtualBackground} = updateResponse.data;
        dispatch(updateCurrentEventAction({defaultVirtualBackground}))
    }
};

export const deleteVirtualBackgroundImageAction = (eventId, updateState = true) => async (dispatch) => {
    const updateResponse = await getInstanceAxios().delete(`${config.NODE_API_URL}/organizers/virtual-background/${eventId}`, {crossDomain: true}
    );
    if (updateResponse && updateResponse.data && updateResponse.data.status === 200 && updateState) {
        dispatch(updateCurrentEventAction({defaultVirtualBackground: null}))
    }
}

export const setWrapupShowsOnlyFirstQuestionAction = (flag) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    const {currentEvent} = getState().controller;

    const {data} = await getInstanceAxios()
        .post(`${config.NODE_API_URL}/organizers/set-wrapup-shows-only-first-question`,
            {
                flag,
                eventId: currentEvent.eventId,
            }
        );

    if (data && data.status === 200) {
        dispatch(setCurrentEventAction({
            ...currentEvent,
            wrapupShowsOnlyFirstQuestion: flag,
        }));
    }

    dispatch(setLoading(false));
    return true;
};

export const saveCustomFAQAction = (data) => async (dispatch, getState) => {
    const {currentEvent} = getState().controller;
    const updateResponse = await getInstanceAxios().post(`${config.NODE_API_URL}/organizers/save-custom-faq`,
        data
    );
    if (updateResponse && updateResponse.data && updateResponse.data.status === 200) {
        dispatch(updateCurrentEventAction({
            faq: [
                ...currentEvent.faq,
                updateResponse.data.faq
            ]
        }));

        return true;
    }

    return false;
};
export const activateCustomFAQAction = (data) => async (dispatch) => {
    const updateResponse = await getInstanceAxios().post(`${config.NODE_API_URL}/organizers/activate-custom-faq`,
        data
    );
};
