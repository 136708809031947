import _ from "lodash";
import types from "../constans";
import store from "../../store";
import config from "../../utils/config";
import socketModule from "../../utils/socketModule";
import {
    resetPartnersBioProgressesFetchedAction,
    setOtherUserAction,
    setOtherUserBAction,
    unsetNextPartnerAction
} from "../users/actions";
import { clearFishbowlOptionsAction } from "../fishbowl/actions";
import { sendZoomStatistics } from "../zoom/actions";
import { partnerConnectedAction, setVideoConnectedAction } from "../room/actions";
import { getInstanceAxios, getSyncDateNow, prepareEventData, prepareUserData } from "../../utils/helpers";
import {
    setCheckinFishbowlMiniPopupAction,
    setEventRegistrationInfoAction,
    setTargetJoinPopupAction
} from "../checkin/actions";
import { errorReasons } from "../../utils/constants";

export const emptyUser = {
    userId: '',
    firstName: '',
    lastName: '',
    email: '',
    emailKey: '',
    title: '',
    company: '',
    profilePhoto: '',
    profileColorHex: '',
    bio: '',
    location: '',
    age: '',
    region: '',
    content: {},
    notes: [],
    eventTags: {},
    organizerMode: false,
    inRoom: false,
    viewedIntroToMeetaway: false,
    ticket: null,
    invoice: null
};

export const removeEventLogoAction = (logoUrl) => {
    return {
        type: types.REMOVE_EVENT_LOGO,
        logoUrl
    }
};

export const addEventLogoAction = (logoUrl) => {
    return {
        type: types.ADD_EVENT_LOGO,
        logoUrl
    }
};

export const updateCurrentEventAction = (updatedCurrentEvent) => {
    return {
        type: types.UPDATE_CURRENT_EVENT,
        updatedCurrentEvent
    }
};

export const setAppModeAction = (appMode) => {
    return {
        type: types.SET_APP_MODE,
        appMode
    }
};

export const setDirectModeAction = (directMode) => {
    return {
        type: types.SET_DIRECT_MODE,
        directMode
    }
};

export const setAllDataProvidedAction = (allDataProvided = true) => async (dispatch) => {
    dispatch({
        type: types.ALL_DATA_PROVIDED,
        allDataProvided
    });
};

export const setContainerLoadedAction = () => ({ type: types.SET_CONTAINER_LOADED});

export const setAccessGrantedErrorBlockVisibilityAction = (isVisible, reasons) => {
    return {
        type: types.SET_ACCESS_GRANTED_ERROR_BLOCK_VISIBILITY,
        isVisible,
        reasons
    }
};

export const setDisconnectedAction = (disconnected, reasons) => {
    return {
        type: types.SET_DISCONNECTED_INFO,
        disconnected,
        reasons
    }
};

export const setErrorInfoBlockVisibleAction = (isVisible, reasons) => async (dispatch) => {
    if (isVisible) {
        let reason;
        if (reasons.accessDenied) {
            reason = errorReasons['REASON_ACCESS_DENIED'];
        } else if (reasons.noConnection) {
            reason = errorReasons['REASON_NO_CONNECTION'];
        } else if (reasons.networkError) {
            reason = errorReasons['REASON_NETWORK_DISCONNECTED'];
        } else if (reasons.networkWarning) {
            reason = errorReasons['REASON_NETWORK_UNSTABLE'];
        } else if (reasons.reconnectingWarning) {
            reason = errorReasons['REASON_RECONNECTING'];
        }
        if (reason) {
            dispatch(sendZoomStatistics(
                {
                    reason,
                    method: "FE showErrorInfoBlock",
                }, 'Message'
            ));
        }
    }

    return dispatch({
        type: types.SET_ERROR_INFO_BLOCK_VISIBILITY,
        isVisible,
        reasons
    });
};

export const setMaxMembersBlockVisibilityAction = (isVisible) => {
    return {
        type: types.SET_MAX_MEMBERS_BLOCK_VISIBILITY,
        isVisible
    }
};

export const setDeviceChangingAction = (deviceChanging) => {
    return {
        type: types.SET_DEVICE_CHANGING,
        deviceChanging,
    }
};

export const setDevice = (payload) => {
    store.dispatch({
        type: types.SET_DEVICE,
        payload,
    })
};

export const switchView = (view) => async (dispatch, getState) => {
    const {
        controller: { screenView, currentSession: { roomNumber, sessionNumber }, currentEvent: { eventId, name, internalEndDateAndTime } },
        users: { currentUser: {userId, firstName, lastName}},
        zoom: { zmClient }
    } = getState();

    if (roomNumber !== '') {
        socketModule.socket.emit('leave_from_room', {
            eventId,
            roomNumber
        })
    }

    if (view === 'holding' && view !== screenView) {
        if (getSyncDateNow() > Date.parse(internalEndDateAndTime)) {
            return dispatch(moveToWrapupAction())
        }
        window.parent.postMessage({
            userHitsHolding: {
                message: `User ${firstName} ${lastName} with id ${userId} hits holding screen in event ${name} (id: ${eventId}) on session ${sessionNumber}`
            }
        }, '*');
    }

    if (view === 'video') {
        dispatch(resetPartnersBioProgressesFetchedAction());
        setTimeout(()=>{
            socketModule.socket.emit('connect_to_room', {
                eventId,
                roomNumber,
                userId
            })
        }, 1000);
    }
    if (screenView === 'video') {
        dispatch({ type: types.TRIGGER_CALL_ENDED });
    }

    dispatch({
        type: types.UNSET_VIDEO_HAS_BEEN_CONNECTED
    });

    if (!(view === 'holding' && screenView === 'holding')) {
        dispatch({
            type: types.SWITCH_CURRENT_VIEW,
            view,
            reInit: view === 'video'
        });
    }

    if (screenView === 'video' && view === 'video') {
        if (zmClient) zmClient.leave();
    }

    if (view === 'break') {
        dispatch(partnerConnectedAction(false));
    }
};

export const setProfileView = (isProfileView) => async (dispatch) => {
    dispatch({
        type: types.SET_DISPLAY_PROFILE,
        isProfileView,
    });
}

export const setLoadedContainer = (isLoaded) => async (dispatch) => {
    dispatch({
        type: types.SET_LOADED_CONTAINER,
        isLoaded,
    });
}

export const switchFishbowlViewAction = (fishbowlView) => (dispatch) => {
    dispatch({
        type: types.SWITCH_FISHBOWL_VIEW,
        fishbowlView
    });

    dispatch(showFishbowlPopupAction(fishbowlView !== ''));
};

export const setSidebarTabAction = (sidebarTab) => {
    return {
        type: types.SET_SIDEBAR_TAB,
        sidebarTab
    }
};

export const setCurrentEventAction = (event) => {
    return {
        type: types.SET_CURRENT_EVENT,
        event
    }
};

export const setPreviousSessionNum = (previousSessionNum) => {
    return {
        type: types.SET_PREVIOUS_SESSION_NUM,
        previousSessionNum
    }
};

export const refreshCurrentUserSessionAction = () => async (dispatch, getState) => {
    if (getState().controller.directMode) return;
    const userSession = await dispatch(getCurrentUserSession());
    if (userSession) {
        dispatch(setCurrentUserSessionAction(userSession))
    }
};

export const setCurrentUserSessionAction = (userSession) => {

    const {
        id, number_users, user_id, other_user_a_id, other_user_b_id, room_number,
        session_completed, session_end_time, session_number, session_start_time, show_break_page,
        video_completed, style_match, video_url, both_connected, did_connected, video_connected,
        counterOfCreatedEvents
    } = userSession;

    return {
        type: types.SET_CURRENT_USER_SESSION,
        userSession: {
            id: id,
            numberUsers: number_users,
            userId: user_id,
            otherUserAId: other_user_a_id,
            otherUserBId: other_user_b_id,
            roomNumber: room_number,
            sessionEndTime: session_end_time,
            sessionNumber: session_number,
            sessionStartTime: session_start_time,
            showBreakPage: show_break_page,
            styleMatch: style_match,
            videoUrl: video_url,
            sessionCompleted: session_completed,
            videoCompleted: video_completed,
            videoConnected: video_connected,
            didConnected: did_connected,
            bothConnected: both_connected,
            counterOfCreatedEvents: counterOfCreatedEvents,
        }
    }
};

export const showNextSessionPopupAction = (isVisible) => ({ type: types.SHOW_NEXT_SESSION_POPUP, isVisible });

export const showRightFlyInPopupAction = (isVisible) => (dispatch) => {
    if (isVisible) {
        dispatch(show3rdPartyPopupAction(false));
        dispatch(showExtendCallPopupAction(false));
    }
    dispatch({ type: types.SHOW_RIGHT_FLYIN_POPUP, isVisible });
};

export const showFoundRightFlyInAction = (isVisible) => (dispatch) => {
    if (isVisible) {
        dispatch(show3rdPartyPopupAction(false));
        dispatch(showExtendCallPopupAction(false));
    }
    dispatch({ type: types.SHOW_FOUND_RIGHT_FLYIN, isVisible });
};

export const show3rdPartyPopupAction = (isVisible) => ({type: types.SHOW_3RD_PARTY_POPUP, isVisible});

export const showSurveyPopupAction = (isVisible) => ({type: types.SHOW_SURVEY_POPUP, isVisible});

export const hideSurveyPopupAction = () => (dispatch) => {
    dispatch({ type: types.HIDE_SURVEY_POPUP });
    setTimeout(() => {
        dispatch(needShowSurveyPopupAction(false));
        dispatch(showSurveyPopupAction(false))
    }, 500);
};

export const hideNextPartnerBioPopupAction = () => (dispatch, getState) => {
    const { showNextPartnerBioPopup } = getState().controller;
    if (showNextPartnerBioPopup) {
        dispatch({ type: types.HIDE_NEXT_PARTNER_BIO_POPUP });
        setTimeout(() => {
            dispatch(showNextPartnerBioPopupAction(false))
        }, 500);
    }
};

export const showNextPartnerBioPopupAction = (isVisible) => ({type: types.SHOW_NEXT_PARTNER_BIO_POPUP, isVisible});

export const hideJoinBy3rdPartyAction = (flag) => ({ type: types.HIDE_JOIN_BY_3RD_PARTY, flag });

export const showExtendCallPopupAction = (isVisible) => (dispatch) => {
    if (isVisible) {
        dispatch(show3rdPartyPopupAction(false));
    }
    dispatch({ type: types.SHOW_EXTEND_CALL_ACTION, isVisible });
};

export const showWrapupNotesPopupAction = (visible) => ({type: types.SHOW_WRAPUP_NOTES_POPUP, visible});

export const hideWrapupNotesPopupAction = () => (dispatch) => {
    dispatch({ type: types.HIDE_WRAPUP_NOTES_POPUP });
    setTimeout(() => {
        dispatch(showWrapupNotesPopupAction(false));
    }, 500);
};

export const showWrapupEmailPopupAction = (visible) => ({type: types.SHOW_WRAPUP_EMAIL_POPUP, visible});

export const hideWrapupEmailPopupAction = () => (dispatch) => {
    dispatch({ type: types.HIDE_WRAPUP_EMAIL_POPUP });
    setTimeout(() => {
        dispatch(showWrapupEmailPopupAction(false));
    }, 500);
};

export const showEmailPopupAction = (visible, mode = '', currentEvent = null) => (dispatch) => {
    if (currentEvent) dispatch(setCurrentEventAction(prepareEventData(currentEvent)));
    dispatch({type: types.SHOW_EMAIL_POPUP, visible, mode});
};

export const hideEmailPopupAction = (key) => (dispatch) => {
    dispatch({ type: types.HIDE_EMAIL_POPUP });
    setTimeout(() => {
        dispatch(showEmailPopupAction(false));
        dispatch(setEmailPopupDataAction({key, payload: {}}));
    }, 500);
};

export const shareYourEmailAction = (payload) => () => {
   getInstanceAxios().post(`${config.NODE_API_URL}/events/share-email`, payload, {crossDomain: true});
};

export const setEmailPopupDataAction = ({key, payload}) => ({ type: types.SET_EMAIL_POPUP_DATA, key, payload});

export const setExtendCallBlockedAction = (flag) => ({ type: types.SET_EXTEND_CALL_BLOCKED, flag });

export const setExtendCallDirectModeAction = () => ({ type: types.SET_EXTEND_CALL_DIRECT_MODE });

export const setExtendCallByMinutesAction = (minutes) => ({ type: types.SET_EXTEND_CALL_BY_MINUTES, minutes });

export const clearExtendCallOptionsAction = () => ({ type: types.CLEAR_EXTEND_CALL_OPTIONS });

export const showFishbowlPopupAction = (isVisible) => (dispatch, getState) => {

    const {
        controller: { showNextSessionPopup, showRightFlyInPopup },
        fishbowl: { fishbowlPartyView },
    } = getState();

    dispatch(clearFishbowlOptionsAction());

    switch (true) {
        case isVisible && (showNextSessionPopup || showRightFlyInPopup) && fishbowlPartyView !== 'found' :
            dispatch({
                type: types.SHOW_FISHBOWL_POPUP,
                isVisible: false
            });
            break;
        case isVisible && fishbowlPartyView === 'found' && showNextSessionPopup :
            dispatch(showNextSessionPopupAction(false));
            dispatch({
                type: types.SHOW_FISHBOWL_POPUP,
                isVisible
            });
            break;
        default:
            dispatch({
                type: types.SHOW_FISHBOWL_POPUP,
                isVisible
            });
    }

    if (!isVisible) {
        dispatch({type: types.UNSET_FISHBOWL_VIEW});
        dispatch(dropFishbowlUserAction());
    }
};

export const setSelectedNextSessionVariantAction = (variant) => {
    return {
        type: types.SET_SELECTED_NEXT_SESSION_VARIANT,
        variant
    }
};

export const setSurveySubmitButtonAction = (button) => {
    return {
        type: types.SET_SURVEY_SUBMIT_BUTTON,
        button
    }
};

export const setSurveySuccessfullyPostedAction = (flag) => ({ type: types.SET_SURVEY_SUCCESSFULLY_POSTED, flag });

export const processMovingToRoom = () => async (dispatch, getState) => {
    const { controller: { currentSession: { numberUsers, styleMatch }, screenView } } = getState();

    dispatch(showFishbowlPopupAction(false));
    dispatch(showFoundRightFlyInAction(false));
    if ( !(screenView === 'break' && (parseInt(numberUsers) === 3 || parseInt(numberUsers) === 2) && styleMatch === 'fishbowl') ) {
        await dispatch(setVideoCompletedAction({ onlyAxios: true }));
        const nextView = await dispatch(getNextViewBasedOnUserSession());

        if (nextView !== 'survey') await dispatch(setSessionCompletedAction());

        dispatch(setPreviousSessionNum(null))
    }
    await dispatch(getNextViewBasedOnUserSession());

    return getState().controller.currentSession.roomNumber;
};

export const setVideoCompletedAction = ({ onlyAxios = false, wrapupFromFishbowl = false, updateEndTime = false }) => async (dispatch, getState) => {
    const {
        controller: {
            currentEvent: { eventId, internalEndDateAndTime },
            currentSession: { sessionNumber, roomNumber, numberUsers, otherUserAId, otherUserBId },
            previousSessionNum,
            nextSessionVariant,
            screenView
        },
        users: { currentUser: { userId } },
        room: { videoHasBeenConnected }
    } = getState();

    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/user-sessions/set-video-completed`, {
        eventId,
        userId,
        sessionNumber,
        roomNumber,
        updateEndTime,
        previousSessionNum
    }, {crossDomain: true});

    if (response.data.status === 200) {
        dispatch({type: types.MARK_VIDEO_COMPLETED});
    }

    if (onlyAxios) return;
    dispatch(setSurveySubmitButtonAction('next'));
    dispatch(showRightFlyInPopupAction(false));
    dispatch(unsetNextPartnerAction());

    if (screenView === 'break' && nextSessionVariant === 'next') {
        await dispatch(setSessionCompletedAction());
        return await dispatch(getNextViewBasedOnUserSession());
    }

    if (screenView === 'video' && !videoHasBeenConnected) {
        await dispatch(setSessionCompletedAction());
    }

    if (response.data.nextRoomNumber) {
        socketModule.socket.emit('bio_progress.complete_prior_call', {
            eventId,
            userId,
            roomNumber: response.data.nextRoomNumber
        });
    }

    if (nextSessionVariant === 'exit' || wrapupFromFishbowl === true) {
        dispatch(moveUserToWrapupPageAction());
        if ((parseInt(numberUsers) > 1 || otherUserAId || otherUserBId) && videoHasBeenConnected) {
            dispatch(setSurveySubmitButtonAction('exit'));
            dispatch(logging({ message: `Switched to <b>survey</b> screen.`}));
            dispatch(switchView('survey'));
        } else {
            await dispatch(setSessionCompletedAction());
            dispatch(logging({ message: `Moved to wrapup page`}));
            return dispatch(moveToWrapupAction())
        }
    } else {
        const nextView = await dispatch(getNextViewBasedOnUserSession());
        if (nextView === 'video') {
            dispatch(logging({ message: `Switched to <b>video</b> screen. Room number: ${getState().controller.currentSession.roomNumber}`}));
        } else {
            dispatch(logging({ message: `Switched to <b>${videoHasBeenConnected ? 'survey' : nextView}</b> screen.`}));
        }

        if ((videoHasBeenConnected || nextView === 'survey') && getSyncDateNow() > Date.parse(internalEndDateAndTime)) {
            dispatch(setSurveySubmitButtonAction('exit'));
        }
        dispatch(switchView(videoHasBeenConnected ? 'survey' : nextView));
    }
    return null;
};

export const updateSessionEndTimeAction = (sessionEndTime) => {
    return {
        type: types.UPDATE_SESSION_END_TIME,
        sessionEndTime
    }
};

export const setSessionCompletedAction = () => async (dispatch, getState) => {
    const { controller: { currentEvent: { eventId }, currentSession: { sessionNumber, roomNumber }, previousSessionNum }, users: { currentUser: { userId } } } = getState();

    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/user-sessions/set-session-completed`, {
        eventId,
        userId,
        sessionNumber,
        roomNumber,
        previousSessionNum
    }, {crossDomain: true});

    if (response.data.status === 200) {
        dispatch({type: types.MARK_SESSION_COMPLETED});
        dispatch(setOtherUserAction(emptyUser));
        dispatch(setOtherUserBAction(emptyUser));
    }
};

export const markSessionAsDisconnectedAction = () => async (dispatch) => {
    console.log('markSessionAsDisconnectedAction');
    if (await dispatch(setVideoConnectedAction(false))) {
        dispatch({ type: types.MARK_SESSION_DISCONNECTED });
    }
};

export const moveUserToWrapupPageAction = () => async (dispatch) => {
    await dispatch(changeUserAvailabilityAction(-1))
};

export const changeUserAvailabilityAction = (availability) => async (dispatch, getState) => {
    const { controller: { currentEvent: { eventId } }, users: { currentUser: { userId } } } = getState();

    await getInstanceAxios().post(`${config.NODE_API_URL}/event-registrations/set-user-availability`, {
        eventId,
        userId,
        availability
    }, {crossDomain: true});
};

export const checkActiveSessionAction = () => async (dispatch, getState) => {
    const { controller: { currentEvent: { eventId } }, users: { currentUser: { userId } }, checkin: { currentSessionNumber } } = getState();
    const response = await getInstanceAxios().get(`${config.NODE_API_URL}/user-sessions/${eventId}/${userId}/get-active?session_number=${currentSessionNumber}`, {crossDomain: true});
    if (response.data.status !== 200) return;
    if (response.data.hasActiveSession) {
        dispatch(showRightFlyInPopupAction(true));
        dispatch(setTargetJoinPopupAction(false));
    }
    if (response.data.hasActiveFishbowlSearch) {
        dispatch(setCheckinFishbowlMiniPopupAction(true));
        dispatch(setTargetJoinPopupAction(false));
    }
};

export const needShowRightFlyinAction = (flag) => ({type: types.NEED_SHOW_RIGHT_FLYIN, flag});
export const needShowSurveyPopupAction = (flag) => ({type: types.NEED_SHOW_SURVEY_POPUP, flag});

export const userDeclineInviteAction = (userId) => {
    return {
        type: types.USER_DECLINE_INVITE,
        userId
    }
};

export const clearAcceptedInviteAction = () => ({type: types.CLEAR_ACCEPTED_INVITE});

export const setPartyViewAction = (partyView) => (dispatch) => {
    dispatch ({
        type: types.SET_PARTY_VIEW,
        partyView
    });
};

export const userExtendCallAction = () => async (dispatch, getState) => {
    const {
        controller: { currentEvent: { eventId, minPerSession }, currentSession: { sessionNumber, roomNumber } },
        users: { currentUser: { userId }, otherUser: { userId: otherUserId }, otherUserB: { userId: otherUserBId } }
    } = getState();

    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/user-sessions/extend-call`, {
        eventId,
        userId,
        sessionNumber,
        roomNumber,
        minPerSession,
        threeWayCall: otherUserId && otherUserBId
    }, {crossDomain: true});

    const { status, sessionEndTime } = response.data;
    if (status === 200) {
        dispatch(updateSessionEndTimeAction(sessionEndTime));
        dispatch(sessionHasBeenExtendedAction());
    }
};

export const sessionHasBeenExtendedAction = () => ({ type: types.SESSION_HAS_BEEN_EXTENDED });

const updateOtherUserInStore = (userId, sessionNumber, otherUserB = false) => async (dispatch, getState) => {
    const { eventId } = getState().controller.currentEvent;
    const otherUserResponse = await getInstanceAxios().get(`${config.NODE_API_URL}/users/${userId}?event_id=${eventId}&session_number=${sessionNumber}`, {
        crossDomain: true
    });
    if (otherUserResponse.data.status === 200) {
        if (!otherUserB) {
            dispatch(setOtherUserAction(prepareUserData(otherUserResponse.data.userData)));
        } else {
            dispatch(setOtherUserBAction(prepareUserData(otherUserResponse.data.userData)));
        }
    } else {
        if (!otherUserB) {
            dispatch(setOtherUserAction(emptyUser));
        } else {
            dispatch(setOtherUserBAction(emptyUser));
        }
    }
};

export const pushUserSessionToStore = (userSession) => async (dispatch) => {
    const { session_number, other_user_a_id, other_user_b_id } = userSession;

    dispatch(setCurrentUserSessionAction(userSession));

    if (other_user_a_id) {
        await dispatch(updateOtherUserInStore(other_user_a_id, session_number))
    }

    if (other_user_b_id) {
        await dispatch(updateOtherUserInStore(other_user_b_id, session_number, true));
    }
};

export const getCurrentUserSession = () => async (dispatch, getState) => {
    const { controller: { currentEvent: { eventId }, directMode }, users: { currentUser: { userId } } } = getState();

    console.log('getCurrentUserSession', {eventId, userId});

    if (directMode || !eventId || !userId) return;
    const userSessionResponse = await getInstanceAxios().get(`${config.NODE_API_URL}/user-sessions/${eventId}/${userId}/session`);

    return userSessionResponse.data.userSession;
};

export const getCurrentUserSessions = () => async (dispatch, getState) => {
    const { controller: { currentEvent: { eventId }, directMode }, users: { currentUser: { userId } } } = getState();

    if (directMode || !eventId || !userId) return;
    const response = await getInstanceAxios().get(`${config.NODE_API_URL}/user-sessions/${eventId}/${userId}/sessions`);

    if (response.data.status === 200) {
        dispatch(setUserSessionAction(response.data.userSessions));
    }

    return response.data.userSessions;
};

export const setUserSessionAction = (userSessions) => ({ type: types.SET_USER_SESSIONS_ACTION, userSessions });

export const safeUserSessionBreak = ({sessionNumber, isBreak}) => async (dispatch, getState) => {
    const { controller: { currentEvent: { eventId } }, users: { currentUser: { userId } } } = getState();

    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/user-sessions/add-break`, {
        eventId,
        userId,
        sessionNumber: parseFloat(sessionNumber),
        isBreak
    }, {crossDomain: true});

    if (response.data.status === 200) {
        dispatch(getCurrentUserSessions());
    }
}

export const getNextViewBasedOnUserSession = () => async (dispatch, getState) => {
    const { controller: { currentEvent: { eventId } }, users: { currentUser: { userId } } } = getState();
    const userSession = await dispatch(getCurrentUserSession());
    if (!userSession) {
        return 'holding';
    } else {

        const { session_number, other_user_a_id, other_user_b_id } = userSession;

        dispatch(setCurrentUserSessionAction(userSession));

        if (other_user_a_id) {
            await dispatch(updateOtherUserInStore(other_user_a_id, session_number));

            if (other_user_b_id) {
                await dispatch(updateOtherUserInStore(other_user_b_id, session_number, true));
            }
        }

        const {controller: { currentSession }} = getState();
        if (!currentSession.showBreakPage && !currentSession.videoCompleted) {
            return 'video';
        }
        if (currentSession.showBreakPage && !currentSession.videoCompleted) {
            return 'break';
        }
        if (currentSession.videoCompleted) {
            return 'survey';
        }
    }
    dispatch(logging({
        channel: 'sys_logs.add_log',
        message: `HOLDING BYPASS - EVENT: ${eventId} USER: ${userId} STATE: ${JSON.stringify(getState())}`,
        type: 'system'
    }));
    return 'holding';
};


export const createFishbowlUserAction = () => async (dispatch, getState) => {
    const { controller: { currentEvent: { eventId }, currentSession: { sessionNumber } }, users: { currentUser: { userId } } } = getState();
    const userSessionResponse = await getInstanceAxios().post(`${config.NODE_API_URL}/fishbowl/create-from-videosystem`, {
        eventId,
        userId,
        sessionNumber: parseFloat(sessionNumber),
        fromHolding: true
    }, {crossDomain: true});
    const data = userSessionResponse.data;

    return (data.status === 200 && data.userSession) ? data.userSession : null;
};

export const dropFishbowlUserAction = () => async (dispatch, getState) => {
    const { controller: { currentEvent: { eventId }, currentSession: { sessionNumber } }, users: { currentUser: { userId } } } = getState();
    if (eventId && userId) {
        await getInstanceAxios().post(`${config.NODE_API_URL}/fishbowl/create-from-videosystem`, {
            dropFishbowl: true,
            eventId,
            userId,
            sessionNumber: parseFloat(sessionNumber)
        }, {crossDomain: true});
    }
};

export const createUsersessionForBreakAction = () => async (dispatch, getState) => {
    const { controller: { currentEvent: { eventId }, currentSession: { sessionNumber } }, users: { currentUser: { userId } } } = getState();
    const userSessionResponse = await getInstanceAxios().post(`${config.NODE_API_URL}/user-sessions/create-for-break`, {
        eventId,
        userId,
        sessionNumber: parseFloat(sessionNumber)
    }, {crossDomain: true});
    const data = userSessionResponse.data;
    return (data.status === 200 && data.userSession) ? data.userSession : null;
};

export const setUserSessionPartyViewAction = (partyView) => async (dispatch, getState) => {
    const { controller: { currentEvent: { eventId }, currentSession: { sessionNumber, roomNumber } }, users: { currentUser: { userId } } } = getState();
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/user-sessions/set-party-view`, {
        eventId,
        userId,
        sessionNumber: parseFloat(sessionNumber),
        roomNumber,
        partyView
    }, {crossDomain: true});

    if (response.data.status !== 200) {
        console.error(`
            Error while setting user session party view as ${partyView} for event: ${eventId}, 
            user: ${userId}, session number: ${sessionNumber}`, response.data.message
        );
    }
};

export const getHeartBeatInfo = () => async (dispatch, getState) => {
    const {
        controller: { currentEvent: { eventId }, currentSession : { roomNumber} },
        users: { currentUser }
    } = getState();

    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/user-sessions/heartbeat-info`, {
        eventId,
        userId: currentUser.userId,
        roomNumber
    }, { crossDomain: true });

    if (response.data.status !== 200) {
        console.error(`
            Error while checking heartbeat info. Error: `, response.data.message
        );
        return false;
    } else {
        console.warn(response.data.heartbeatInfo);
        return response.data.heartbeatInfo;
    }
};

export const submitSurveyFormAction = (data, type = 'survey') => async (dispatch) => {
    if (data && data.length) {
        await Promise.all(data.map((item) => {
            return getInstanceAxios().post(`${config.NODE_API_URL}/events/save-intersession-survey`, {
                event_id: item.event,
                user_id: item.user,
                user_session_id: item.user_session_id || null,
                session_number: item.session_number,
                other_user_id: item.other_user || null,
                share_email: item.followup_option ? parseInt(item.followup_option) === 1 : false, // followup_option
                conversation_rating: item.rate_conversation || null, // rate_conversation
                feedback_complete: type === 'survey',
            }, {crossDomain: true});
        }));
    }

    if (type === 'survey') {
        setTimeout(() => {
            dispatch(setSurveySuccessfullyPostedAction(true))
        }, 2000)
    }
};

export const hideObjectivesAction = () => async (dispatch, getState) => {
    const { controller: { currentEvent: { eventId } }, users: { currentUser: { userId } }, checkin: { eventRegistration } } = getState();
    await getInstanceAxios().put(`${config.NODE_API_URL}/event-registrations/hide-objectives`, {
        eventId,
        userId,
    }, {crossDomain: true});
    console.warn("hideObjectivesAction");
    dispatch(setEventRegistrationInfoAction({...eventRegistration, showObjectives: false}))
};

export const moveToWrapupAction = () => async (dispatch, getState) => {
    const { controller: { currentEvent: { eventId, intersessionSurvey, customSurveyUrl } }, users: { currentUser: { emailKey, userId } } } = getState();
    const isDating = intersessionSurvey === "dating-v1";

    await getInstanceAxios().post(`${config.NODE_API_URL}/fishbowl/clear-user-fishbowl`, {
        eventId
    });

    if (isDating) {
        return window.parent.postMessage({moveToWrapup: true}, '*');
    }

    if (customSurveyUrl) {
        await getInstanceAxios().post(`${config.NODE_API_URL}/events/disable-prompt-to-complete-survey`, {
            eventId
        });
        return window.location.href = customSurveyUrl;
    }

    return window.location.href = `${config.VIDEOSYSTEM_MAIN_URL}/wrapup/${eventId}?email_key=${emailKey}&user_id=${userId}`;
};

export const logging = ({message, channel = 'activity.update_activity', type = 'user', status = null}) => (dispatch, getState) => {
    const {
        controller: { currentEvent: {eventId} },
        users: { currentUser: { userId } }
    } = getState();

    socketModule.socket.emit(channel, {
        eventId,
        userId,
        data: {
            type,
            status,
            message
        }
    });
};

export const loggingAsUser = ({message, userId}) => (dispatch, getState) => {
    const {
        controller: { currentEvent: {eventId} },
    } = getState();

    socketModule.socket.emit('activity.update_activity', {
        eventId,
        userId,
        data: {
            type: 'user',
            status: null,
            message
        }
    });
};

export const sendEmailMessageAction = ({event_id, subject, html}) => async () => {
    const endpoint = `${config.NODE_API_URL}/users/send-email-message`;
    try {
        await getInstanceAxios().post(endpoint, {
            event_id,
            subject,
            html,
        });
        return 'Success';
    } catch (e) {
        if (e.response && e.response.status === 429) {
            return 'Too many requests, try again later...';
        }
        return 'Something went wrong, try again later...';
    }
};

export const saveEventFeedbackAction = ({eventId, userId, feedback, numericType, wrapupSurvey, emailKey}) => async (dispatch, getState) => {
    const {
        users: { currentUser }
    } = getState();
    const endpoint = currentUser && currentUser.userId ? `${config.NODE_API_URL}/events/wrapup/auth-save-feedback` : `${config.NODE_API_URL}/events/wrapup/save-feedback`;
    try {
        await getInstanceAxios().post(endpoint, {
            eventId,
            userId,
            feedback,
            numericType,
            wrapupSurvey,
            emailKey,
        });
        return true
    } catch (e) {
        console.error(e);
        return false
    }
};

export const needSocketReconnectAction = (flag) => ({ type: types.NEED_SOCKET_RECONNECT, flag });
export const disableGreenScreenAction = () => ({ type: types.DISABLE_GREEN_SCREEN });

export const setControllerStore = (store) => ({ type: types.SET_CONTROLLER_STORE, store });

export const updateDifferenceBetweenServerAndClientTime = ({eventId=null}) => async (dispatch, getState) => {
    setTimeout(async ()=> {
        const {
            users: { currentUser: { userId } }
        } = getState();
        try {
            const url = userId ? 'update-difference-time-protected' : 'update-difference-time';
            const localTime = Date.now();
            const response = await getInstanceAxios().post(`${config.NODE_API_URL}/event-registrations/${url}`, {
                eventId,
                userId,
                localTime
            }, { crossDomain: true });

            const differenceTime = _.get(response, 'data.differenceTime', null);
            const status = _.get(response, 'data.status', null);

            if (status === 200 && differenceTime) {
                dispatch({
                    type: types.SET_DIFFERENCE_TIME,
                    differenceBetweenServerAndClientTime: Number(differenceTime),
                })
            }
        } catch (e) {
            console.error('updateDifferenceBetweenServerAndClientTime', e);
        }
    }, 1000);
};

export const setFirstVisitedCreateEventAction = () => async () => {
    await getInstanceAxios().post(`${config.NODE_API_URL}/events/visited-create-event-page`, {}, {crossDomain: true});
};

export const getIsEventExist = () => async () => {
    const location = window.location.href;
    const currentEventId = location.split('/').slice(-1)[0];
    const redirectURL = config.NODE_API_URL.includes('videodev') ? 'https://videodev.meetaway.com' : 'https://video.meetaway.com';

    if (!currentEventId) {
        return document.location = redirectURL;
    }

    const response = await getInstanceAxios().get(`${config.NODE_API_URL}/events/check-is-exist/${currentEventId}`, {crossDomain: true});
    const status = _.get(response, 'data.status', null);

    if (!status || response.data.status === 404) {
        return document.location = redirectURL;
    }

    return true;
};

export const showEditBreaksPopupAction = (flag) => ({ type: types.SET_IS_OPEN_EDIT_BREAKS_POPUP, flag });
