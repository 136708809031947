import _ from "lodash";
import DetectRTC from "detectrtc";
import * as Sentry from "@sentry/react";
import ZoomVideo from "@zoom/videosdk";

import types from "../constans";
import config from "../../utils/config";
import socketModule from "../../utils/socketModule";
import { errorReasons } from "../../utils/constants";
import { getInstanceAxios } from "../../utils/helpers";
import { clearRoomInitialParams, endCallAction, hideJoinBlockAction, setIsVisitorAloneAction } from "../room/actions";
import {
    setAccessGrantedErrorBlockVisibilityAction,
    setDisconnectedAction,
    setErrorInfoBlockVisibleAction,
    setMaxMembersBlockVisibilityAction
} from "../controller/actions";


export const getToken = (roomName) => async (dispatch, getState) => {
    dispatch(setIsFetching(true));
    const { controller: { currentEvent: { eventId } }} = getState();

    const endpoint = `${config.NODE_API_URL}/zoom/tokens`;
    const response = await getInstanceAxios().post(endpoint, {
        roomName,
        eventId
    });

    if (response && response.data && response.data.status === 200) {
        dispatch(setRoomType(response.data.room_type));
        dispatch(setRoomName(roomName));
        dispatch(clearRoomInitialParams());
        dispatch({type: types.INIT_ROOM_CONTROLLER_SUCCESS});
        setTimeout(()=>dispatch(setIsFetching(false)), 5000);

        return response.data;
    } else {
        const recordingError = new Error(
            response.data.error ? response.data.error.message : "There was an error updating recording rules"
        );
        recordingError.code = response.data.error ? response.data.error.code : null;
        dispatch(endCallAction());
        setTimeout(()=>dispatch(setIsFetching(false)), 5000);
        return dispatch(setError(recordingError));
    }
};

export const dispatchError = (error) => (dispatch) => {
    return dispatch(setError(error));
};

export const setError = (error) => async (dispatch) => {
    console.error(error);

    dispatch(sendZoomStatistics({
        method: 'FE setError',
        message: error.message,
        code: error.code,
        name: error.name
    }));

    dispatch({
        type: types.SET_ZOOM_ERROR,
        error
    });
};

export const initParticipants = (participants) => (dispatch) => {
    return dispatch({
        type: types.INIT_ZOOM_PARTICIPANTS,
        participants
    });
};

export const sendZoomStatistics = (data, type) => (dispatch, getState) => {
    console.log('sendZoomStatistics', { data, type });

    const {
        controller: {
            currentEvent: {eventId},
            currentSession: {roomNumber}
        },
        users: {currentUser: {userId}}
    } = getState();

    const detectRTC = window.detectRtcData || DetectRTC;
    const browser = detectRTC ? detectRTC.browser.name : 'n/d';
    const browserVersion = detectRTC ? detectRTC.browser.version : 'n/d';
    const osName = detectRTC ? detectRTC.osName : 'n/d';
    const osVersion = detectRTC ? detectRTC.osVersion : 'n/d';

    const dataObject = {
        eventId, roomNumber, userId, data,
        browser: `${browser} (${browserVersion})`,
        os: `${osName} (${osVersion})`,
        type: type ? type : 'FE error',
    };

    socketModule.socket.emit('zoom.statistics', dataObject);

    if (dataObject.type === 'FE error') {
        const reason = data.message || data.reason || type || 'Unknown reason';
        Sentry.captureException(new Error(reason), scope => {
            scope.setTag("scope", "Zoom");
            scope.setContext("extra-data", dataObject);
        });
    }
}

export const setIsFetching = (isFetching) => ({
    type: types.SET_ZOOM_IS_FETCHING,
    isFetching
});

export const setRoomType = (roomType) => ({
    type: types.SET_ZOOM_ROOM_TYPE,
    roomType
});

export const setRoomName = (roomName) => ({
    type: types.SET_ZOOM_ROOM_NAME,
    roomName
});

export const showErrorInfoBlock = (reason) => (dispatch) => {
    dispatch(setDisconnectedAction(false, {}));
    dispatch(setIsVisitorAloneAction(false));
    dispatch(setMaxMembersBlockVisibilityAction(false));

    const reasons = {
        common: false,
        accessDenied: false,
        noConnection: false,
        networkError: false
    };

    switch (reason) {
        case errorReasons['REASON_ACCESS_DENIED'] :
            reasons.accessDenied = true;
            break;
        case errorReasons['REASON_NO_CONNECTION'] :
            reasons.noConnection = true;
            break;
        case errorReasons['REASON_DISCONNECTED'] :
        case errorReasons['REASON_NETWORK_DISCONNECTED'] :
            reasons.networkError = true;
            break;
        case errorReasons['REASON_NETWORK_UNSTABLE'] :
            reasons.networkWarning = true;
            break;
        case errorReasons["REASON_RECONNECTING"] :
            reasons.reconnectingWarning = true;
            break;
        default :
            reasons.common = true;
            break;
    }

    dispatch(hideJoinBlockAction());
    dispatch(setErrorInfoBlockVisibleAction(true, reasons));
};

export const showAccessGrantedErrorBlock = (reason) => (dispatch) => {
    dispatch(setDisconnectedAction(false, {}));
    dispatch(setIsVisitorAloneAction(false));
    dispatch(setMaxMembersBlockVisibilityAction(false));
    dispatch(hideJoinBlockAction());

    const reasons = {
        mediaAccessDenied: false,
        webcamBlocked: false,
        webcamAccessDenied: false
    };

    switch (reason) {
        case errorReasons['REASON_ACCESS_DENIED'] :
            reasons.mediaAccessDenied = true;
            break;
        case errorReasons['REASON_WEBCAM_IS_BLOCKED'] :
            reasons.webcamBlocked = true;
            break;
        case errorReasons['REASON_WEBCAM_ACCESS_DENIED'] :
            reasons.mediaAccessDenied = true;
            reasons.webcamAccessDenied = true;
            break;
        default :
            return;
    }

    dispatch(sendZoomStatistics(
        {
            reason: reasons,
            method: "FE showErrorInfoBlock",
        }, 'Message'
    ));

    dispatch(setAccessGrantedErrorBlockVisibilityAction(true, reasons));
};

export const addParticipant = (participant) => (dispatch, getState) => {
    const { zoom: { participants}} = getState();
    const index = participants.findIndex(({userIdentity}) => !userIdentity);
    const data = participants;
    if (index !== -1) data[index] = participant;

    return dispatch({
        type: types.ADD_ZOOM_PARTICIPANTS,
        participants: data
    });
};

export const updateParticipant = (participant) => (dispatch, getState) => {
    const { zoom: { participants}} = getState();
    const data = participants.map((item)=> {
        if (participant?.userIdentity === item?.userIdentity) {
            return participant;
        }
        return item;
    });

    return dispatch({
        type: types.UPDATE_ZOOM_PARTICIPANTS,
        participants: data
    });
};

export const disconnectParticipant = (userIdentity) => (dispatch, getState) => {
    const { zoom: { participants}} = getState();
    const data = participants.map((item)=> {
        if (userIdentity === item?.userIdentity) {
            return {};
        }
        return item;
    });

    return dispatch({
        type: types.DISCONNECT_ZOOM_PARTICIPANTS,
        participants: data
    });
};


export const getChatMessages = (isExistEvent) => async (dispatch, getState) => {
    const {
        controller: {
            currentEvent: { eventId },
            currentSession: { roomNumber }
        }
    } = getState();

    const urlRoomName = window.location.href
        .replace(/#|!/g, "")
        .match(/[^\/]+$/)[0];

    const params = new URLSearchParams({
        eventId,
        isRoomMode: isExistEvent ? 0 : 1,
        roomNumber: roomNumber || urlRoomName
    }).toString();

    const endpoint = `${config.NODE_API_URL}/zoom/chat-messages?${params}`;
    const response = await getInstanceAxios().get(endpoint);

    const messages = _.get(response, 'data.messages', []);

    return messages;
};

export const getWebinarChatMessages = () => async (dispatch, getState) => {
    const {
        controller: { currentEvent: { eventId } },
    } = getState();

    const params = new URLSearchParams({ eventId }).toString();
    const endpoint = `${config.NODE_API_URL}/zoom/webinar/chat-messages?${params}`;
    const response = await getInstanceAxios().get(endpoint);
    const messages = _.get(response, 'data.messages', []);

    console.log('getWebinarChatMessages', messages);

    return messages;
};

export const setInstalledVideoSDK = (reInit = false) => async (dispatch, getState) => {
    const { zoom: { installedVideoSDK } } = getState();

    if (installedVideoSDK && !reInit) return true;
    if (reInit) ZoomVideo.destroyClient();

    const zmClient = ZoomVideo.createClient();
    await ZoomVideo.preloadDependentAssets(`${window.location.origin}/lib`);
    await zmClient.init("en-US", `${window.location.origin}/lib`, {
        webEndpoint: window?.webEndpoint ?? "zoom.us",
        stayAwake: true,
        patchJsMedia: true,
        leaveOnPageUnload: true,
        enforceVirtualBackground: true,
        enforceMultipleVideos: {disableRenderLimits: true}, // https://devforum.zoom.us/t/subscribe-video-counts-greater-than-maximum-size-4/113916/10
    })

    return dispatch({
        type: types.SET_ZOOM_CLIENT,
        zmClient
    });
};
export const setIsBackgroundSelectionOpen = (isOpen) => ({
    type: types.SET_ZOOM_IS_BACKGROUND_SECTION_OPEN,
    isOpen
});
